import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueWechatTitle from 'vue-wechat-title'  //引入
import Video from "video.js";
import "video.js/dist/video-js.min.css"
Vue.prototype.$video = Video
// import VueResource from 'vue-resource'

Vue.use(ElementUI);
Vue.use(VueWechatTitle) 
// Vue.use(VueResource);
Vue.config.productionTip = false
import './directives/directive';
import './plugins';
import 'vant/lib/index.css';
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
