import Vue from 'vue'
import VueRouter from 'vue-router'
import isMobile from '../isMobile'
Vue.prototype.$isMobile = isMobile

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: "/Homes"
  }, {
    path: '/HomeS',
    component: () => import("../views/HomeS.vue")
  },
  {
    path: '/FAQ',
    component: () => import("../views/FAQ.vue")
  },
  {
    path: '/ShoesPage',
    component: () => import("../views/ShoesPage.vue")
  },
  {
    path: '/WordPage',
    component: () => import("../views/WordPage.vue")
  }, {
    path: '/NewShoes',
    component: () => import("../views/NewShoes.vue")
  },
  {
    path: '/ShowPage',
    component: () => import("../views/ShowPage.vue")
  },
  {
    path: '/ReservePage',
    component: () => import("../views/ReservePage.vue")
  },
  {
    path: '/LandingPage',
    component: () => import("../views/LandingPage.vue"),
    meta: {
      title: "衣服、鞋子都有"
    }
  },
  {
    path: '/LandingPage2',
    component: () => import("../views/LandingPage2.vue"),
    meta: {
      title: "衣服、鞋子都有"
    }
  },
  {
    path: '/LandingPage3',
    component: () => import("../views/LandingPage3.vue"),
    meta: {
      title: "衣服、鞋子都有"
    }
  },
  {
    path: '/LandingPage4',
    component: () => import("../views/LandingPage4.vue"),
    meta: {
      title: "衣服、鞋子都有"
    }
  },
  {
    path: '/TestDemo',
    component: () => import("../views/TestDemo.vue"),
    meta: {
      title: "衣服、鞋子都有"
    }
  },
  {
    path: '/TestDemo2',
    component: () => import("../views/TestDemo2.vue"),
  },
  {
    path: '/TouSu',
    component: () => import("../views/TouSu.vue")
  },
  {
    path: '/WxTransfer',
    component: () => import("../views/WxTransfer.vue"),
    meta: {
      title: "wx中转页面"
    }
  },
  {
    path: '/NewPage',
    component: () => import("../views/NewPage.vue"),
    meta: {
      title: "落地页"
    }
  },
  {
    path: '/NewPage2',
    component: () => import("../views/NewPage2.vue"),
  },
  //  lv + 冲锋衣
  {
    path: '/NewPage3',
    component: () => import("../views/NewPage3.vue"),
  },
  {
    path: '/NewPage5',
    component: () => import("../views/NewPage5.vue"),
  },
  {
    path: '/WordPage2',
    component: () => import("../views/WordPage2.vue"),
  },
  {
    path: '/WordPage3',
    component: () => import("../views/WordPage3.vue"),
  },
  {
    path: '/AboutUs',
    component: () => import("../views/AboutUs.vue"),
    meta: {
      title: "关于我们"
    }
  },
  {
    path: '/DrawAward',
    component: () => import("../views/DrawAward.vue"),
    meta: {
      title: "抽奖页面"
    }
  },
  {
    path: '/BarragePage1',
    component: () => import("../views/BarragePage1.vue"),
    meta: {
      title: "抽奖页面"
    }
  },
  {
    path: '/BarragePage6',
    component: () => import("../views/BarragePage6.vue"),
    meta: {
      title: "抽奖页面"
    }
  },
  {
    path: '/Random',
    component: () => import("../views/RTest.vue"),
    meta: {
      title: "随机"
    }
  },
  {
    path: '/call/login',
    component: () => import("../views/call/ToLogin.vue"),
    meta: {
      title: "登录"
    }
  },
  {
    path: '/call/index',
    component: () => import("../views/call/CallIndex.vue"),
    meta: {
      title: "首页"
    }
  },
  {
    path: '/call/upload',
    component: () => import("../views/call/CallUpload.vue"),
    meta: {
      title: "上传页面"
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  console.log("请求：" + to.path)

  let isInTime;

  const currentHour = new Date().getHours();

  const startHour = 21;
  const endHour = 9;

  console.log("startHour:" + startHour);
  console.log("endHour:" + endHour);

  if (currentHour >= startHour || currentHour < endHour) {
    console.log("It's between");
    isInTime = true;
  } else {
    console.log("It's not between");
    isInTime = false;
  }

  // if (!isMobile()) {
  //   // 这里是pc端
  //   isInTime = false;
  //   console.log("pc端");
  // } else{
  //   console.log("手机访问");
  // }  
  
  isInTime = false;
  
  if (!isInTime) {
    next()
    console.log("时间不再范围内");
  } else{
    if (to.path == "/ReservePage") {
      next({ path: '/NewPage' });
      console.log("时间在范围内，请求NewPage");
  
    } else if (to.path == "/ReservePage1"){
      next({ path: '/ReservePage' });
    } else if (to.path == "/ReservePage2"){
      next({ path: '/NewPage3' });
    } else if (to.path == "/ReservePage3"){
      next({ path: '/ReservePage' });
    } else if (to.path == "/ReservePage4"){
      next({ path: '/ReservePage' });
    } else {
      next()
    }
  }

  
})
export default router
