import Vue from 'vue';
import { Swipe, SwipeItem, Form, Field, Button, NavBar, Cell, DatetimePicker, Popup, RadioGroup, Radio, Uploader, Toast, Dialog } from 'vant';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Cell);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Uploader);
Vue.use(Toast);
Vue.use(Dialog);